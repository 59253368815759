a.clickable {
  color: inherit;
}

a.clickable:hover {
  color: inherit;
}

a.highlighted {
  color: inherit;
  text-decoration: underline;
}

a.highlighted:hover {
  color: inherit;
}

.divider {
  width: 10px;
  height: auto;
  display: inline-block;
}

.small-divider {
  width: 5px;
  height: auto;
  display: inline-block;
}

.large-divider {
  width: 30px;
  height: auto;
  display: inline-block;
}

.link-text {
  color: #007bff;
  cursor: pointer;
}

.link-text:hover {
  text-decoration: underline;
}

.date {
  font-weight: normal;
  font-size: normal;
}

.gen {
  font-weight: bold;
  font-size: large;
}

.small {
  font-size: small;
}

.medium {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.modal-background {
  background-color: lightgray;
}

.label {
  font-size: small;
  font-weight: 500;
}

.danger {
  color: red;
}

.green {
  color: green;
}

.brand-text {
  color: blue;
}

.comment:hover {
  text-decoration: underline;
}

.vertical-divider {
  margin-bottom: 10px;
}

.click:hover {
  cursor: pointer;
}

.popular-packages {
  background-color: #f2f2f2;
  color: black;
}

.popular-packages .heading,
.popular-packages .heading:hover {
  color: black;
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
}

.heading {
  font-weight: bold;
  font-size: 18px;
}

.small-heading {
  font-weight: bold;
  font-size: 16px;
}

.profile-heading {
  font-weight: 700;
  font-size: 20px;
}

.package-heading {
  font-weight: 600;
  font-size: 22px;
}

.button-text {
  font-weight: 400;
  font-size: 14px;
}

/*
 * Add styling so we can override react-router <Link> component
*/

.link {
  color: gray;
  font-size: 16px;
  font-weight: 450;
  border-bottom: 2px solid transparent;
  padding: 8px;
}

.link:hover {
  color: gray;
  text-decoration: none;
  border-bottom: 2px solid gray;
}

.link-active {
  border-bottom: 2px solid gray;
}

.path {
  font-size: 14px;
  cursor: pointer;
}

.gen-header {
  font-weight: 400;
  font-size: 16px;
}

.login-header {
  font-weight: 400;
  font-size: 20px;
}

.error {
  font-size: small;
  color: red;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-color: white;
}

.large-avatar {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-color: white;
}

.selected {
  color: black;
  font-weight: bold;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul li {
  margin-top: 1px;
  background-color: white;
  padding: 10px;
}

.clickable:hover {
  cursor: pointer;
  text-decoration: underline;
}

.footer {
  position: absolute;
  bottom: 0;
}

.revoke {
  color: red;
}

.revoke:hover {
  text-decoration: underline;
  cursor: pointer;
}

.list-item {
  height: auto;
  padding: 5px 5px 5px;
  border-bottom: 1px solid #e0e0e0;
}

.list-item:hover {
  background-color: lightgray;
}

.List {
  width: 100%;
  margin-top: 15px;
}

.List:focus {
  outline: 0;
}

input.streamlined {
  border: none;
  background: transparent;
  padding: 5px 5px 5px;
  border-bottom: 1px solid black;
}

input.streamlined:focus {
  outline: 0;
}

.pkg-name {
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.pkg-name:hover {
  text-decoration: underline;
}

.chn-name {
  font-size: 14px;
  font-weight: 450;
}

.dropdown {
  color: black;
}

.token-input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    padding-left: 10px;
    width: 100%;
    background-color: white;
}
